.Rated_wrapper {
  margin-top: 34px;
  margin-bottom: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  color: white;
  max-width: 100%;

  &.Rated_wrapper_fullwidth {
    width: 100%;
    margin: 0;

    .Rated_inner {
      max-width: 100%;
      width: 100%;
      border-radius: 0px;
      padding: 56px 24px;
    }
  }

  .Rated_title {
    font-size: 29px;
    margin-bottom: 16px;
    font-weight: 900;
    color: white;
  }

  .Rated_inner {
    padding: 18px 32px;
    border-radius: 18px;
    text-align: center;
    max-width: 100%;

    ul {
      font-size: 19px;

      li {
        margin-inline-end: 3px !important;
      }
    }
  }
}

.Rated_rate {
  margin-left: 8px;
  text-align: left;

  ul {
    padding-inline-start: 0 !important;
  }
}

.Rated_image {
  margin-bottom: 3px;
}

.Rated_ratedText a {
  margin-top: 2px;
  color: #c1c6db;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.Rated_rate_container {
  display: flex;
  justify-content: center;
  align-items: center;
  // filter: grayscale(1);
}

.Rated_badges {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  // filter: grayscale(1);
}

.Rated_badge_element {
  margin-left: 6px;
  margin-right: 6px;
}

.wallOfLoveLinkWrapper {
  text-align: center;
  margin-top: 12px;
}

.wallOfLoveLink {
  font-weight: 500;
  font-size: 18px;
  color: white;
}

.linkLove {
  color: white;
  text-decoration: underline;
}
.Rated_rate_container_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 520px) {
  .Rated_badges {
    flex-wrap: wrap;
    justify-content: center;
  }
  .Rated_rate_container_wrapper {
    flex-wrap: wrap;
  }
}