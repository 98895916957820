.wrapper {
    margin-bottom: 44px;
    max-width: 100%;
    margin-left: 12px;
    margin-right: 12px;
}

.subtitle {
    margin-bottom: 44px;
    text-align: center;
}

.vignetteWrapper {
    margin-bottom: 32px;
    text-align: left;
}

.vignetteInner {
    display: flex;
}

.titleWrapper {
    margin-bottom: 44px;
}
.title {
    text-align: center;
    margin-bottom: 12px;
    font-size: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.vignetteTitle {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(270deg, #1f3a45 10.33%, #1f3a45 34.36%, #1f3a45 60.15%, #8949f1 84.04%, #531dab 92.04%, #531dab);
    -webkit-background-clip: text;
    background-clip: text;
    display: inline;
}

.vignetteDescription {
    margin-top: 8px;

}

.vignetteIcon {
    font-size: 36px;
    color: #531dab;
}

.content {
    margin-top: 3px;
    margin-left: 20px;
}

@media (max-width: 900px) {
    .vignetteInner {
        // flex-wrap: wrap;
    }

}