.elementFeatureList {
    margin-top: 34px;
    margin-bottom: 24px;

    li {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        font-size: 18px;

        .check {
            color: white;
            background: radial-gradient(#14d74f 58%, white 72%);
            border-radius: 50%;
            font-size: 22px;
        }

        .checkGrey {
            background: grey;
            border: transparent;
        }
    }
}

.title {
    font-size: 34px;
    margin-bottom: 12px;
    text-align: center;
}

.wrapper {
    margin: 12px;
    max-width: 100%;
}

.featureText {
    margin-left: 12px;
}

.inner {
    // box-shadow: 0 0 14px rgb(0 0 0 / 4%);
}

.list {
    display: flex;
    width: 1100px;
    max-width: 100%;
    justify-content: center;
    margin: auto;
    margin-top: 32px;
    flex-wrap: wrap;
}

.element {
    background-color: white;
    width: 315px;
    border-radius: 30px;
    padding: 32px;
    min-height: 250px;
    margin: 16px;
    position: relative;
    max-width: 100%;

    .tag {
        position: absolute;
        top: 32px;
        right: 32px;
        font-weight: bold;
        border: none;
        padding: 6px 14px;
    }
    .tagRelativeAnonymousWrapper{
        position: relative;
        display: flex;
    }
    .tagRelativeAnonymous {
        font-weight: bold;
        border: none;
        padding: 6px 14px;
        margin-top: 12px;
        display: flex;
        align-items: center;
    }

    .icon {
        font-size: 44px;
        padding-bottom: 12px;
    }

    .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .desc {
        font-size: 16px;
        color: #4f4f4f;
    }
}

@media (max-width: 680px) {
    .inner {
        padding: 0px;
    }
    .element{
        margin: 6px;
    }
}