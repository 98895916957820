@import "../presets.module.scss";

.wrapper {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #f5f9fc;
    transition: 1s all;
}

.sticky {
    background-color: white;
    box-shadow: 0px 2px 20px #0000001a;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    z-index: 1900;
}

.inner {
    display: flex;
    width: 1300px;
    max-width: 100%;
    margin: auto;
    padding-left: 12px;
    padding-right: 12px;

}

.left {
    align-items: center;
    display: flex;
}

.logo {
    // padding: 8px 12px;
    min-height: 45px;
    display: flex;
    align-items: center;
    width: 100%;

}

.right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;


}

.headerbtn {
    padding: 2px 16px !important;
    font-size: 15px;
}

.menu {
    display: flex;
    list-style-type: none;

    a {
        // color: white;
    }
}

.menu>li {
    line-height: 24px;
}

.menu>li {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 12px;
    height: 48px;
}

.selectCustom {
    // background-color: red;

    &>.ant-select-selector {
        height: 48px;
    }

}

.poweredByBuddies {
    color: #292929;
    font-weight: bold;
}

.powerdby {
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 4px;
    border-top: 1px solid #f0f0f0;
    color: #686868;
}

.buddiesHeader {
    background: linear-gradient(130deg, #020849, #531dab);
    border-radius: 10px;
    color: white !important;
    padding: 8px 12px;

    .shortTitle {
        color: white !important;
    }
}

.iconMenu {
    font-size: 12px !important;
}

ul.menuHorizontal {
    border-bottom: 0;
    background-color: transparent;
    color: #020849;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    li.ant-menu-submenu-horizontal:after {
        border-bottom-width: 0px !important;
        border: none !important;
    }
}

.elementEE {
    height: 52px !important;
}

.elementEEBud {
    height: 62px !important;

    background-color: transparent !important;
}

@media (max-width: 850px) {
    .logo {
        padding-left: 1px;

    }

    .wrapper {
        height: 68px;
    }

    .iconMenu {
        display: none;
    }

    .elementEE,
    .elementEEBud {
        padding-left: 0 !important;
    }

}