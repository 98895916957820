.howItWorks {
    text-align: center;
    margin-top: 90px;
}

.howItWorksTitle {
    font-size: 48px;
    margin-bottom: 12px;
    text-align: center;
}

.howItWorksSubtitle {
    margin-top: 12px;
    font-size: 20px;
    color: #626262;
}

.gettingStartedTitle {
    font-size: 28px;
}

.icon {
    margin-right: 12px;
    color: #1677ff;
}

.gettingStartedWrapper {
    width: 660px;
    max-width: 100%;
    margin: auto;
}

.gettingStartedElement {
    margin-top: 64px;
    margin-bottom: 64px;
}

.gettingStartedTitle {
    margin-bottom: 16px;
}

.productScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px;
    border-radius: 10px;
    background-color: white;
    width: fit-content;
    margin: auto;
    margin-top: 24px;
}