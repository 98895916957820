.wrapper {
    margin: 12px;
    position: relative;
    display: flex;
    justify-content: center;
}

.inner {
    // background: linear-gradient(273deg, #c5e4fa, #badffa);
    padding: 84px 64px;
    width: 880px;
    max-width: 100%;
    position: relative;
    border-radius: 48px;
}

.title h2 {
    font-size: 36px;
    margin-bottom: 14px;
}

.subtitle {
    font-size: 20px;
    color: #626262;
    margin-bottom: 26px;
}

.actions {
    display: flex;
    align-items: center;

}

.hint {
    color: #626262;
    margin-left: 16px;
}

.icon {
    font-size: 60px;
    margin-bottom: 0px;
}

.logo {
    margin-left: -16px;
}

@media (max-width: 760px) {
    .actions {
        flex-wrap: wrap;
    }

    .hint {
        color: #626262;
        margin-top: 16px;
        margin-left: 0px;
        flex-basis: 100%;
    }
}

@media (max-width: 600px) {
    .inner {
        padding: 54px 34px;
    }
    .title h2{
        font-size: 30px;
    }
}