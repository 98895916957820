.wrapper {
    margin-top: 120px;
    margin-bottom: 120px;
}

.inner {}

.title {
    font-size: 34px;
    text-align: center;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.ctaBottom {
    margin-top: 36px;
    text-align: center;
}