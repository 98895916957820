.wrapper {
    margin-top: 124px;
    margin-bottom: 184px;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1480px;
    margin: auto;
    max-width: 100%;
    position: relative;

    &.hideContent{
        height: 430px;
    }
}

.title {
    text-align: center;
    margin-bottom: 12px;

    h2 {
        font-size: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

.subtitle {
    text-align: center;
    margin-bottom: 24px;
}

.actions {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}
