.itemWrapper {
    margin: 12px;
    text-align: center;
    width: 230px;
    max-width: 100%;
    height: 280px;
    border-radius: 10px;
    margin-top: 90px;
    box-shadow: 3px 3px 13px 0px rgb(0 0 0 / 7%);
}

.itemInner {
    padding: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.itemLogo {
    margin-top: -82px;
}

.itemVerb {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bolder;
}

.lastName {
    font-weight: bold;
    padding: 2px 6px;
    width: fit-content;
    text-align: center;
    margin: auto;
    margin-bottom: 8px;
}

.itemName {
    font-weight: 900;
    width: fit-content;
    background-color: #00000017;
    text-align: center;
    margin: auto;
    padding: 2px 6px;
    margin-top: 6px;
    margin-bottom: 4px;
}

.itemTitle {
    font-size: 16px;
    flex-grow: 1;
    align-items: center;
    display: flex;

}

.itemAction {
    margin-top: 12px;
}