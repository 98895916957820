.buddies {
    font-weight: 900;
    font-size: 12px;
    margin-left: 4px;
}

.logoText {
    font-size: 9px;
    color: #1f3a45;
    text-decoration: none;
    font-weight: 100;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    line-height: 12px;

    &.white {
        color: white;
    }
}