.footer {
  overflow: hidden;
  padding-top: 130px;
  margin-top: -100px;
  position: relative;
}

.Footer_wrapper {
  text-align: center;
  width: 100%;
  background-color: #172125;
  color: white;
  min-height: 120px;
  font-size: 16px;
  position: relative;
  z-index: 1;
}

.Footer_rounded {
  background: #172125;
  height: 300px;
  border-radius: 990% / 650% 650% 0% 0%;
  width: 150%;
  margin-left: -25%;
  margin-top: -60px;
  position: absolute;
  z-index: 0;
}

.useCaseLink {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  a {
    padding-bottom: 0 !important;
  }
}

.Footer_inner {
  padding: 90px 40px;
}

.Footer_section_subtitle {
  line-height: 21px;
}

.Footer_content {
  color: #b1b1b1;
  height: 100%;
  display: flex;
  justify-content: space-around;
  max-width: 1000px;
  margin: auto;
}

.Footer_section {
  width: 300px;
  max-width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;

  .Footer_section_title {
    font-size: 18px;
    padding-bottom: 12px;
    color: #e5e5e5;
    font-weight: bold;
  }

  a,
  .Footer_section_link {
    padding-bottom: 16px;
    display: block;
    font-size: 16px;
    color: #b1b1b1;
    line-height: 20px;
  }
}

.apps a {
  margin-bottom: 0;
  padding-bottom: 2px;
}

.Footer_section_bottom {
  color: #b1b1b1;
  text-align: center;
}

.tag {
  border: none;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .Footer_content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .Footer_section {
    width: 100%;
  }
}