.logoWrapper {
    display: flex;
    align-items: center;
}

.logoText {
    font-size: 16px;
    color: black;
    text-decoration: none;
    font-weight: 100;
}
.logoWhite{
    color: white;
}
.buddies {
    font-weight: 900;
    font-size: 20px;
}