.wrapper {
    padding: 20px 14px;
    text-align: center;
    background-color: #f9f0ff;
}

.title {
    margin-top: 24px;
}

.logos {
    display: flex;
    align-items: center;
    justify-content: space-around;
    filter: contrast(0.4);
    width: 1100px;
    max-width: 100%;
    margin: auto;
    margin-top: 14px;

    .logo {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 8px;
    }
}

.UsedBy_inner_wrapper {
    width: 200%;
    overflow: hidden;
    position: relative;
    min-height: 100px;
    height: 100px;
}

.UsedBy_inner {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 200%;
    position: absolute;
    left: 0;
    filter: grayscale(1) contrast(0.1);
    opacity: 0.8;

    img {
        margin-left: 38px;
        margin-right: 38px;
    }
}

@keyframes scroll {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

.animate {
    animation: scroll 60s linear infinite;
}



@media (max-width: 990px) {
    .logos {
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 800px) {
    .animate {
        animation: scroll 30s linear infinite;
    }
}