$bay-10: #fdfeff;
$bay-50: #eef4ff;
$bay-100: #e0ebff;
$bay-200: #c8d9fd;
$bay-300: #a6c1fb;
$bay-400: #829df7;
$bay-500: #647bf0;
$bay-600: #4856e3;
$bay-700: #353ec0;
$bay-800: #313aa2;
$bay-900: #2f3780;
$bay-950: #1b1f4b;

.white {
    color: white;
}

.pacifico {
    font-family: 'Pacifico', cursive;
}

.capitalize {
    text-transform: capitalize;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.flex {
    display: flex;
}

.light {
    font-weight: 300;
}

.bold {
    font-weight: 700;
}

.bolder {
    font-weight: 900;
}

.center {
    text-align: center;
}

.italic {
    font-style: italic;
}

.grey {
    color: $bay-900;
}

.pageBackground {
    background: linear-gradient(180deg, $bay-10 0%, $bay-100)
}