.Number_installs_wrapper {
    position: relative;

    .Number_installs_icon {
        font-size: 22px;
        color: red;
        position: absolute;
        left: -10px;
        top: -14px;
    }
}

.tag {
    font-size: 16px;
    padding: 6px 12px;
    padding-left: 44px;
    text-wrap: wrap;
}
.Number_installs_content{
    display: flex;
}