.main {
  padding-bottom: 68px;
  background: linear-gradient(178deg, #fff9ef 60%, #fffdfa);
}

.firstSectionWrapper {
  text-align: center;
  color: #1f3a45;
  position: relative;
  padding-top: 44px;
  margin-right: 12px;
  margin-left: 12px;

  .mug {
    opacity: 0.08;
    position: absolute;
    right: 5%;
    top: 265px;
    width: 183px;
    height: auto;
    transform: rotate(20deg);
  }

  .grains {
    opacity: 0.08;
    position: absolute;
    left: 5%;
    top: -25px;
    width: 183px;
    height: auto;
  }
}

.firstSectionInner {
  display: flex;
  width: 1280px;
  max-width: 100%;
  margin: auto;
}

.subtitle {
  font-size: 22px;
  line-height: 32px;
  width: 530px;
  max-width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 24px;
}

.actions {
  max-width: 100%;
  width: 370px;
  justify-content: center;
  margin: auto;
  margin-top: 44px;
}

.mainTitle {
  text-align: center;
  font-size: 68px;
  line-height: 98px;
  width: 760px;
  max-width: 100%;
  margin: auto;
}

.icon {
  font-size: 54px;
  margin-bottom: 12px
}

.landingQuote {
  text-align: center;
  font-size: 36px;
  padding-top: 44px;
  padding-bottom: 44px;
  width: 100%;
  // background: #f1f1a0;``
  background: linear-gradient(312deg, #b8eed7, #70e5b3);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.vignettes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
}

.vignetteDesc {
  font-size: 20px;
  font-weight: normal;
  margin-top: 20px;
  color: #626262;

}

.vignetteLeft {
  flex-grow: 1;
}

.vignetteWrapper {
  display: flex;
}

.picture {
  margin-bottom: -85px;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 44px 32px;
  box-shadow: 0px 0px 14px 0px #0000000f;
  border-radius: 34px;
}

.tag {
  border: none;
  padding: 6px 22px;
  font-weight: bold;
}

.tagWrapper {
  display: flex;
  justify-content: center;
}

.hint {
  color: #626262;
  margin-top: 6px;
}

.featureWrapper {
  display: flex;
  justify-content: center;
  margin-top: 84px;
}

.benefitsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 84px;
}

.quoteWrapper {
  margin-top: 84px;
  margin-bottom: 124px;
}

.installWrapper {
  margin-top: 124px;
}

.picturesProductInner {
  display: flex;
  height: 150px;
  overflow-y: hidden;
  overflow-x: visible;
  border-style: solid;
  border-width: 1px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: #ffdb9f;
  max-width: 100%;
  box-shadow: 0px 20px 20px #e5e5e5;


  .picturesProductLeft {
    padding: 24px;
    background-color: white;
    border-radius: 14px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 10px;
    flex-basis: 50%;

    img {
      width: 100%;
      height: auto;

    }
  }

  .picturesProductRight {
    padding: 24px;
    background-color: white;
    border-radius: 14px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: flex-start;
    flex-basis: 50%;

    img {
      width: 100%;
      height: auto;

    }

  }
}

.ratedWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
}

/* Mobile */
@media (max-width: 1070px) {
  .firstSectionWrapper {
    padding-top: 24px;

    .mug {
      opacity: 0.04;
      width: 103px;
    }

    .grains {
      opacity: 0.04;
      width: 103px;
    }
  }

  .mainTitle {
    text-align: center;
    font-size: 48px;
    line-height: 58px;
    width: 560px;
  }

}

@media (max-width: 900px) {
  .picturesProductInner {
    flex-wrap: wrap;
    height: auto;
    border: none;
    border-radius: 14px;

    img {
      width: 600px;
      max-width: 100%;
    }



    .picturesProductLeft {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      margin-right: 0;
      border-bottom: 1px solid #ffdb9f;

      img {
        width: 600px;
        max-width: 100%;
      }
    }

    .picturesProductRight {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      border-radius: 14px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      overflow: hidden;

      img {
        width: 600px;
        max-width: 100%;
      }
    }
  }

  .vignetteLeft {
    flex-basis: 100%;
  }

  .vignetteWrapper {
    flex-wrap: wrap;
  }

  .picture {
    margin-bottom: -85px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 44px 32px;
    box-shadow: 0px 0px 14px 0px #0000000f;
    border-radius: 34px;
    margin-top: 24px;

    .pictureVignette {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 570px) {
  .mainTitle {
    text-align: center;
    font-size: 38px;
    line-height: 48px;
    width: 460px;
  }

  .picture {
    padding: 24px 22px;
  }

  .howItWorks {
    margin-top: 50px;
  }

}

@media (max-width: 420px) {
  .firstSectionWrapper {
    margin-right: 0px;
    margin-left: 0px;

    .mainTitle {
      font-size: 34px;
      line-height: 44px;
    }
  }
}