.logoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
        border-radius: 12px;
        overflow: hidden;
    }

    &:hover .logoTextWrapper {
        text-decoration: none;
    }
}

.logoText {
    margin-left: 6px;
    font-size: 20px;
    color: black;
    text-decoration: none;
    font-weight: 700;
}

.logoWhite {
    color: white;
}

.shortTitleWhite {
    color: white;
}

.logoTextWrapper {
    margin-left: 8px;
    color: #1f3a45;
    text-decoration: none;
    margin-top: -4px;

    &:hover {
        text-decoration: none;
    }

    &.white {
        color: white;
    }

    &.logoTextWrapperCentered {
        margin-top: 0px;
    }
}

.shortTitle {
    color: #545454;
    font-size: 12px;
    line-height: 10px;
}

.shortTitleWhite {
    color: #e2e2e2;
}

.logoLink {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
    text-align: left;
}

@media (max-width: 400px) {
    .shortTitle {
        max-width: 300px;
        text-wrap: wrap;
    }
}