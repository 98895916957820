.mainCTA {
  width: 400px;
  max-width: 100%;
  // background: linear-gradient(237deg, #1f3a45 12%, #152567 24%, #2a4598 51%, #083e56 64%, #1c2e7a 79%, #1f3a45 96%);
  background: black;

  &:hover {
    // background: linear-gradient(237deg, #1f3a45 12%, #152567 24%, #2a4598 51%, #083e56 64%, #1c2e7a 79%, #1f3a45 96%) !important;
    background: black;
  }

  .slackIconInButton {
    margin-left: 4px;
    // filter: drop-shadow(2px 3px 2px grey);
    // background-color: white;
    border-radius: 50%;
    padding: 3px;
  }
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.mainLink {
  max-width: 100%;
}

a.mainLink:hover {
  text-decoration: none;
}

.small {

  .mainCTA {
    min-width: 125px;
    width: auto;
    max-width: 100%;
    padding: 2px 16px;
  }
}