.wrapper {
    padding-top: 24px;
    padding-bottom: 68px;
    width: 1110px;
    max-width: 100%;
    margin: auto;
    position: relative;
}

.inner {
    margin-left: 16px;
    margin-right: 16px;
}

@media (max-width: 800px) {
    .inner {
        margin-left: 10px;
        margin-right: 10px;
    }
}