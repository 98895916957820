.topVignetteProducts {
    display: flex;
    align-items: center;
    padding: 2px 6px;
    width: 140px;
    border-radius: 10px;
    margin: 4px;
    height: 38px;
    position: relative;
    z-index: 1;
}

.productTopText {
    margin-left: 8px;
    font-weight: bold;
    margin-right: 12px;
    font-size: 15px;
    color: #1f3a45;
}