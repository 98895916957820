.title {
    text-align: center;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.subtitle {
    text-align: center;
    margin-bottom: 48px;
}


.loveElementWrapper {
    margin: 12px;
    height: calc(100% - 24px);
}

.loveElementInner {
    padding: 1.25rem;
    width: 330px;
    max-width: 100%;
    border-radius: .5rem;
    border: 1px solid #d9e3ea;
    background: #fcfcfc;
}

.loveElementNameWrapper {
    display: flex;
}

.loveElementNameContent {
    padding-left: 8px;
    margin-top: 3px;
    flex-grow: 1;
}

.loveElementRole {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
}

.loveElementName {
    font-weight: bold;
}

.loveElementContent {
    margin-top: 12px;
    font-size: 15px;
    line-height: 22px;
}

.loveElementDate {
    margin-top: 14px;
    color: #6e6e6e;
    font-size: 14px;
}

.list {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.loveElementOrigin {
    padding-left: 4px;
}

.filters {
    text-align: center;
}

.tagFilter {
    padding: 4px 16px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #eaeaea;
}

.filterTitle {
    margin-bottom: 8px;
    font-weight: bold;
}

.appsTag {
    margin-top: 12px;
}

.ratedWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.followers {
    font-weight: 900;
    font-size: 14px;
    color: #1065c6;
}

.topVoice {
    height: 33px;
}